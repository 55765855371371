import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Brand from '../components/brand'

export default () => (
  <Layout>
    <SEO title="404: Not found" />

    <div
      style={{
        margin: '0',
        width: '300px',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        textAlign: 'center',
      }}
    >
      <Brand />

      <h1>404 NOT FOUND</h1>

      <p>essa página não existe... infelizmente</p>
    </div>

    <div
      style={{
        position: 'absolute',
        width: '75px',
        bottom: '20px',
        webkitAnimationName: 'example' /* Safari 4.0 - 8.0 */,
        webkitAnimationDuration: '4s' /* Safari 4.0 - 8.0 */,
        webkitAnimationIterationCount: 'infinite' /* Safari 4.0 - 8.0 */,
        animationName: 'example',
        animationDuration: '6s',
        animationIterationCount: 'infinite',
        animationTimingFunction: 'linear',
      }}
    >
      <svg viewBox="0 0 18.520834 13.229167" height={50} width={70}>
        <g
          transform="translate(-21.545 -104.736) matrix(2.35702 0 0 2.35702 -124.287 -259.843) matrix(.15875 0 0 .15875 52.872 131.936)"
          fill="#dadada"
          fillOpacity={1}
          strokeWidth={0.24409737}
          display="inline"
        >
          <path
            transform="rotate(45)"
            d="M161.37881 51.214359H171.37881V71.214359H161.37881z"
          />
          <path
            transform="rotate(45)"
            d="M171.37881 41.214359H181.37881V61.214359H171.37881z"
          />
          <path
            transform="rotate(135)"
            d="M51.214359 -181.37881H61.214359V-161.37881H51.214359z"
          />
        </g>
      </svg>
    </div>

    <style global jsx>{`
      /* Safari 4.0 - 8.0 */
      @-webkit-keyframes example {
      }

      /* Standard syntax */
      @keyframes example {
        0% {
          transform: rotate(0);
        }
        100% {
          transform: rotate(-360deg);
        }
      }
    `}</style>
  </Layout>
)
